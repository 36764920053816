/*
 *
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { E_BOTTOM_PANEL, E_LEFT_PANEL } from '../../../core/constants/Enums';
import { Locales } from '../../../core/language/Localize';
import { getNews, getReadArticles } from '../../../core/redux/news.slice';
import {
  getBottomPanel,
  getLeftSidePanel,
  setBottomPanel,
  setLeftSidePanel,
} from '../../../core/redux/uiSettings.slice';
import { ReactComponent as BlueAlert } from '../../assets/icons/blue-dot.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/icons/clipboard.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as DisplayIcon } from '../../assets/icons/display.svg';
import { ReactComponent as EarthIcon } from '../../assets/icons/earth.svg';
import { ReactComponent as LayersIcon } from '../../assets/icons/layers.svg';
import { ReactComponent as LegendIcon } from '../../assets/icons/legend.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/icons/light-bulb.svg';
import { ReactComponent as MagnifierIcon } from '../../assets/icons/magnifier.svg';
import { ReactComponent as NewsIcon } from '../../assets/icons/news.svg';
import BasemapPanel from '../BasemapPanel/BasemapPanel';
import DataLayersPanel from '../DataLayersPanel/DataLayersPanel';
import NewsPanel from '../NewsPanel/NewsPanel';
import Tooltip from '../Reusable/Tooltip/Tooltip';
import SidePanel from '../SidePanel/SidePanel';
import './AppBar.scss';
import AppBarIconButton from './AppBarIconButton';
import './WidgetPanel.scss';

const AppBar = () => {
  const selectedLeftPanel = useSelector(getLeftSidePanel);
  const selectedBottomPanel = useSelector(getBottomPanel);
  const news = useSelector(getNews);
  const readArticleIds = useSelector(getReadArticles);
  const dispatch = useDispatch();
  const [unreadNotif, setUnreadNotif] = useState<boolean>(false);

  const tabMenuItems = [
    {
      label: Locales.Search,
      icon: <MagnifierIcon width={24} height={20} />,
      id: E_LEFT_PANEL.Search,
      disabled: true,
    },
    {
      label: 'Stories',
      icon: <NewsIcon width={24} height={24} />,
      id: E_LEFT_PANEL.News,
      disabled: !!(news.length <= 0),
    },
    {
      label: Locales.Alerts,
      icon: <ClipboardIcon width={24} height={20} />,
      id: E_LEFT_PANEL.Notifications,
      disabled: true,
    },
    {
      label: Locales.Basemap,
      icon: <EarthIcon width={24} height={20} />,
      id: E_LEFT_PANEL.Basemap,
    },
    {
      label: Locales.dataLayers,
      icon: <LayersIcon width={24} height={20} />,
      id: E_LEFT_PANEL.Datalayers,
    },
  ];

  const secondaryTabMenuItems = [
    {
      label: Locales.MyDisplays?.MyDisplays,
      icon: <DisplayIcon width={24} height={20} />,
      id: E_BOTTOM_PANEL.Display,
      disabled: true,
    },
    {
      label: Locales.timelines,
      icon: <ClockIcon width={24} height={20} />,
      id: E_BOTTOM_PANEL.Timeline,
    },
    {
      label: Locales.Legend,
      icon: <LegendIcon width={20} height={20} />,
      id: E_BOTTOM_PANEL.Legend,
    },
  ];

  const handleLeftPanelButtonClick = (panel: E_LEFT_PANEL) => {
    dispatch(setLeftSidePanel(panel === selectedLeftPanel ? undefined : panel));
  };

  const handleBottomPanelButtonClick = (panel: E_BOTTOM_PANEL) => {
    dispatch(setBottomPanel(panel === selectedBottomPanel ? undefined : panel));
  };

  // blue read notification for the appBar
  useEffect(() => {
    if (news.length > 0 && !news.every(({ id }) => readArticleIds.includes(id))) {
      setUnreadNotif(true);
    } else {
      setUnreadNotif(false);
    }
  }, [news, readArticleIds]);

  let sidePanelTitle: string | undefined = undefined;
  if (selectedLeftPanel === E_LEFT_PANEL.News) {
    sidePanelTitle = Locales.News;
  }
  if (selectedLeftPanel === E_LEFT_PANEL.Basemap) {
    sidePanelTitle = Locales.Basemap;
  }

  return (
    <div className={`g-map-view-appbar-container visible`}>
      <div className="g-map-view-appbar">
        <div>
          <ul>
            {tabMenuItems.map((tabItem) => (
              <li
                className={`g-appbar-item
                  ${tabItem.disabled ? 'disabled' : 'enable'}
                `}
                key={tabItem.id}
                style={{ height: 34 }}
              >
                <Tooltip content={tabItem.label}>
                  <div>
                    <AppBarIconButton
                      id={tabItem.id}
                      ariaLabel={tabItem.label}
                      selected={selectedLeftPanel === tabItem.id}
                      onClick={() => handleLeftPanelButtonClick(tabItem.id)}
                    >
                      <>
                        {tabItem.icon}
                        {unreadNotif && tabItem.id === E_LEFT_PANEL.News && <BlueAlert className="blue-alert" />}
                      </>
                    </AppBarIconButton>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>

        <div className="g-bottom-panel-button-group">
          <ul>
            {secondaryTabMenuItems.map((tabItem) => (
              <li
                className={`g-appbar-item
                ${tabItem.disabled ? 'disabled' : 'enable'}
              `}
                key={tabItem.id}
                style={{ height: 34 }}
              >
                <Tooltip content={tabItem.label}>
                  <div>
                    <AppBarIconButton
                      id={tabItem.id}
                      ariaLabel={tabItem.label}
                      selected={selectedBottomPanel === tabItem.id}
                      onClick={() => handleBottomPanelButtonClick(tabItem.id)}
                    >
                      {tabItem.icon}
                    </AppBarIconButton>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <SidePanel
        title={sidePanelTitle}
        openWidth={selectedLeftPanel === E_LEFT_PANEL.News ? 420 : undefined}
        open={selectedLeftPanel !== undefined}
        className="appbar-side-panel"
      >
        <>
          {selectedLeftPanel === E_LEFT_PANEL.Datalayers && <DataLayersPanel />}
          {selectedLeftPanel === E_LEFT_PANEL.Basemap && <BasemapPanel />}
          {selectedLeftPanel === E_LEFT_PANEL.News && <NewsPanel />}
        </>
      </SidePanel>
    </div>
  );
};

export default AppBar;
